import React from 'react';

function App() {
  return (
    <div className="bg-gray-50">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-6xl font-bold text-gray-900 mb-4">
            Browse International Real Estate on the <span className="text-orange-500">Bitcoin Standard</span>
          </h1>
          <div className="max-w-2xl mx-auto my-8">
            <img 
              src="/demo-screenshot.png" 
              alt="Screenshot showing a 2 bedroom apartment listing in El Poblado with Bitcoin price conversion" 
              className="rounded-lg shadow-lg w-full"
            />
          </div>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Instantly view property listings priced in Bitcoin. Save time comparing investments across different markets. Currently available in Colombian Peso (COP) for select sites.
          </p>
          <div className="mt-8">
            <a
              href="https://chromewebstore.google.com/detail/bitcoin-real-estate/oicicfphknnlejofccimgloilodopena"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700"
            >
              Add to Chrome
            </a>
          </div>
        </div>

        {/* Feature Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="text-orange-600 text-2xl mb-4">🏠</div>
            <h3 className="text-lg font-semibold mb-2">Real-Time Conversion</h3>
            <p className="text-gray-600">
              Automatically see property prices in Bitcoin while browsing International real estate listings.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="text-orange-600 text-2xl mb-4">⚡</div>
            <h3 className="text-lg font-semibold mb-2">Instant Comparison</h3>
            <p className="text-gray-600">
              Save time comparing investments across different markets with standardized BTC pricing.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow">
            <div className="text-orange-600 text-2xl mb-4">🌎</div>
            <h3 className="text-lg font-semibold mb-2">Multi-Site Support</h3>
            <p className="text-gray-600">
              Works seamlessly with casacol.co, primaverarealty, and nomadbarrio.
            </p>
          </div>
        </div>

        {/* How It Works Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-center mb-8">How It Works</h2>
          <div className="bg-white rounded-lg shadow p-8">
            <ol className="space-y-6">
              <li className="flex items-start">
                <span className="flex items-center justify-center w-8 h-8 rounded-full bg-orange-100 text-orange-600 font-semibold mr-4">
                  1
                </span>
                <div>
                  <h4 className="font-semibold mb-1">Install the Extension</h4>
                  <p className="text-gray-600">
                    Add the Bitcoin Real Estate Price Converter to Chrome with one click.
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="flex items-center justify-center w-8 h-8 rounded-full bg-orange-100 text-orange-600 font-semibold mr-4">
                  2
                </span>
                <div>
                  <h4 className="font-semibold mb-1">Browse Supported Sites</h4>
                  <p className="text-gray-600">
                    Visit any supported real estate website and browse listings as normal.
                  </p>
                </div>
              </li>
              <li className="flex items-start">
                <span className="flex items-center justify-center w-8 h-8 rounded-full bg-orange-100 text-orange-600 font-semibold mr-4">
                  3
                </span>
                <div>
                  <h4 className="font-semibold mb-1">See BTC Prices Automatically</h4>
                  <p className="text-gray-600">
                    Property prices will automatically display in both COP and BTC.
                  </p>
                </div>
              </li>
            </ol>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center bg-orange-50 rounded-lg p-8">
          <h2 className="text-2xl font-bold mb-4">Ready to Start?</h2>
          <p className="text-gray-600 mb-6">
            Join other international investors browsing International Real Estate on the Bitcoin standard.
          </p>
          <a
            href="https://chromewebstore.google.com/detail/bitcoin-real-estate/oicicfphknnlejofccimgloilodopena"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700"
          >
            Install Extension
          </a>
          <p className="mt-4 text-sm text-gray-500">
            Want to request support for additional websites? Email imyjimmy@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
